#main {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .main-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    a:last-child {
      grid-column: 1 / 3;
    }
  }

  .main-referral {
    background-color: #ff763c26;
    border-radius: 4px;
    padding: 20px 18px 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .main-referral-link {
      display: flex;
      gap: 8px;
      justify-content: space-between;

      .input {
        width: 100%;
        border-radius: 4px;
        background-color: #222b34;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px 8px 10px;
        align-items: center;
      }
    }

    #referral-input {
      cursor: pointer;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
      text-align: left;
      color: #91959a;
      background-color: inherit;
      border: none;
    }

    .main-referral-telegram {
      border: 1.5px solid #ff7222;
      padding: 13px 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      & input {
        background: none;
        border: none;
        color: #ff7222;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
      }
    }

    .main-referral-count {
      color: #ab8883;
      display: flex;
      justify-content: center;
      & p {
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        text-align: left;
      }
    }
  }
}
