.pharmacy-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .info-title {
    margin-bottom: 16px;
    text-align: left;

    .info-distance {
      font-weight: 400;
      font-size: 12px;
      line-height: 13.2px;
      color: #C2C4C7;
    }
  }

  .info-working-hours {
    display: flex;
    text-align: left;

    .working-hours-icon {
      margin-right: 8px;
    }
  }

  .info-availability {
    display: flex;
    text-align: left;

    .availability-icon {
      margin-right: 8px;
    }
  }
}
