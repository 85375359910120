.pharmacies {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px 20px 24px 20px;
  gap: 24px;
  color: #fff;
  background-color: #2a1e26;

  .pharmacy-info {
    background-color: #0a141e;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    min-height: 100px;
  }

  .pharmacy-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .pharmacy-select-options {
      margin-bottom: 16px;

      .select-option {
        display: flex;
        gap: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        text-align: left;
        align-items: center;
        margin-bottom: 12px;

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: inherit;
          border: #e05950 1px solid;

          &:checked {
            -webkit-box-shadow: inset 0px 0px 0px 4px #e059504d;
            -moz-box-shadow: inset 0px 0px 0px 4px #e059504d;
            box-shadow: inset 0px 0px 0px 4px #e059504d;
            border: none;

            &::after {
              content: '';
              display: block;
              background-color: #e05950;
              position: relative;
              border-radius: 50%;
              top: 4px;
              left: 4px;
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }

  #apply-button {
    position: sticky;
    z-index: 10;
    bottom: 20px;
    cursor: pointer;
    height: 48px;
    width: calc(100% - 40px);
    max-width: 576px;
    border-radius: 5px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #ff7222;
    margin: 0 auto;
    left: 20px;
  }

  .pharmacy-search {
    width: 100%;

    .pharmacy-search-types {
      display: flex;
      justify-content: space-between;

      .type {
        width: 100%;
        border-radius: 4px 4px 0px 0px;
        cursor: pointer;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #151F28;

        button {
          cursor: pointer;
          color: #E05950;
        }
      }

      & .active {
        background-color: #e0595026;
      }
    }

    .pharmacy-search-bar {
      input {
        width: 100%;
        color: #fff;
        background-color: #e0595026;
        border: none;
        border-radius: 0px 0px 4px 4px;
        padding: 8px 12px 8px 12px;
      }
    }

    .search-list {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 4px;
      background-color: #151F28;
      max-height: 160px;
      overflow-y: scroll;

      .search-list-option {
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

button {
  margin: 0;
  background-color: transparent;
  border: none;
}
