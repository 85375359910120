.page-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #222b34;
  border-radius: 4px;
  height: 155px;
  padding: 14px 18px 20px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  position: relative;

  &.active {
    &::after {
      position: absolute;
      right: -5px;
      top: -10px;
    }

    &.bluten {
      border: 1px solid #3CB4B9;

      &::after {
        content: url('../../../static/blueten-eclipse.svg');
      }
    }

    &.pharmacy {
      border: 1px solid #E05950;

      &::after {
        content: url('../../../static/pharmacy-eclipse.svg');
      }
    }
  }

  .page-button-name {
    font-family: "IBM Plex Serif", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 28.6px;
    text-align: left;
  }

  .page-button-text {
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.2px;
    text-align: left;
    color: #c2c4c7;
    .additional-text {
      color: #91959a;
      font-size: 8px;
    }

    p {
      display: flex;
      flex-direction: column;
    }
  }

  &:last-child {
    grid-column: 1 / 3;
  }

  &.bluten {
    background-image: url('../../../static/cannabinoids.svg');
    background-position: right;
  }

  &.extract {
    background-image: url('../../../static/extracts.svg');
    background-position: right;
  }

  &.pharmacy {
    background-image: url('../../../static/pharmacy.svg');
    background-position: right;
  }

  &.not-available {
    color: #ffffff26;

    .page-button-text {
      color: #ffffff26;
    }
  }
}

h2,
p {
  margin: 0;
}
