#action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
  padding-top: 30px;
  border-top: 1px solid #222b34;
  color: #ffffff;

  .back-button {
    a {
      img {
        margin-right: 4px;
      }
    }
  }

  .reset-button {
    cursor: pointer;

    img {
      margin-right: 4px;
    }
  }
}
