@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Serif';
  src: local('IBM Plex Serif'), url('./IBM_Plex_Serif/IBMPlexSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}