.caption {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
  .caption-header {
    display: flex;
    align-items: center;
    gap: 10px;

    .caption-header-text {
      font-family: "IBM Plex Serif", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 33.6px;
      text-align: left;

      h1 {
        margin: 0;
      }
    }
  }

  .caption-text {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;

    p {
      margin: 0;
    }
  }
}
