.filters {
  max-width: 576px;
  background-color: #151f28;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 20px 24px 20px;
}

.filter-box-name {
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  margin: 0 0 12px;
  width: 100%;
  text-align: left;
}

.filter-box-item {
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 50px;
  background-color: rgba(255, 118, 60, 0.15);
  color: rgba(255, 118, 60, 1);
  margin-right: 5px;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;

  &.active {
    color: #0a141e;
    background-color: #ff7222;
  }
}

.filter-box-value {
  background-color: #ff763c26;
  outline: none;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  flex: 0 1 40px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.filter-box {
  border-bottom: 1px solid #000;
  padding: 24px 17px;
  display: flex;
  flex-wrap: wrap;

  &:last-child {
    border-bottom: none;
  }

  #range-slider-gradient {
    background-color: #0a141e;
    height: 14px;
    flex: 1 1;
    margin: 0px 12px;
    top: 6px;

    .range-slider__range {
      background-color: #ff763c;
    }

    .range-slider__thumb {
      width: 14px;
      height: 14px;
      background-color: #ff763c;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: #9b3e0a;
        border-radius: 50%;
        transform: translateY(2px);
        margin: 0px auto;
      }
    }
  }

  .dominance {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.filter-genetik, .filter-treatment {
  margin-bottom: 24px;
}

.filter-genetik-buttons, .filter-treatment-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pharm-radio-options {
  margin-top: 24px;
  padding: 0px 24px;
  width: 100%;

  .dominance {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .dominance-option {
      display: flex;
      gap: 10px;

      label {
        cursor: pointer;
      }
    }
  }
}

#apply-button {
  position: sticky;
  z-index: 10;
  bottom: 20px;
  cursor: pointer;
  height: 48px;
  width: calc(100% - 40px);
  max-width: 576px;
  border-radius: 5px;
  font-weight: 600;
  color: #FFFFFF;
  background-color: #ff7222;
  margin: 0 auto;
}

#apply-button:disabled {
  background-color: #0a141e;
}

.dominance-option {
  cursor: pointer;
  input {
    display: none;
  }
}

.dominance-radio-option {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: #FF763C 1px solid;
  position: relative;

  &.active {
    background-color: #FF763C4D;

    &:after {
      content: '';
      position: relative;
      border-radius: 50%;
      top: 5px;
      left: 5px;
      display: block;
      width: 8.47px;
      height: 8.47px;
      background-color: #FF763C;
    }
  }
}
