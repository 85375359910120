.filter-box.filter-price {
  padding: 20px 20px 24px 20px;
  flex-direction: column;
  max-width: 576px;
  background: #897dfd26;
  margin: 0 auto 20px auto;
  border-radius: 20px;
  overflow: hidden;
  gap: 20px;

  #range-slider-gradient {
    width: 100%;
    margin: 0;

    .range-slider__range {
      background-color: #897dfd;
    }

    .range-slider__thumb {
      background-color: #897dfd;
      &::before {
        background: #1a0642;
        box-shadow: 1px 1px 2px 0px #08014b29;
      }
    }
  }

  .filter-box-values {
    display: flex;
    justify-content: space-between;
    
    .filter-box-value {
      background: #897dfd26;
    }
  }
}
