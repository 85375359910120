.container {
    color: white;
    text-align: center;
    background-color: #0A141E;
  
    &.pharmacy {
      background-color: #2a1e26;
  
      .tabs {
        background-color: #151f28;
  
        .tab.active {
          background-color: #2a1e26;
        }
      }
    }
  
    &.filter {
      background-color: #151f28;
  
      .tabs {
        background-color: #2a1e26;
  
        .tab.active {
          background-color: #151f28;
        }
      }
    }
  }