.selected-values {
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  bottom: 0;
  gap: 6px;

  .value {
    display: flex;
    align-items: center;
    padding: 4px 14px 4px 10px;
    gap: 10px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #FFFFFF26;
  }
}
