.modal-overlay {
  position: absolute;
    width: 90vw;

  .modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 300px;
    background-color: #222b34;
    box-shadow: 0px 0px 10px 0px #000000;
    box-sizing: border-box;
    width: 336px;
    padding: 20px;
    gap: 16px;
    border-radius: 10px;
    z-index: 1;

    .plus {
      display: flex;
      gap: 8px;
    }

    p {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;
    }
  }
}
