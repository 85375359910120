.dropdown {
  margin-bottom: 8px;
}

.dropdown-header {
  cursor: pointer;
  background-color: #ff763c26;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 10px 12px;
  border-radius: 4px;
}

.dropdown-main {
  height: 360px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .dropdown-value {
    display: flex;
    justify-content: left;

    .dropdown-value-select {
      input {
        accent-color: #ff763c26;
      }
    }
  }

  .dropdown-main-search {
    background-color: #222b34;
    display: flex;
    padding: 8px 10px 8px 10px;
    gap: 4px;
    border-radius: 4px;
    align-items: center;

    input {
      background-color: #222b34;
      border: none;
      width: 100%;
      color: #FFF;
    }
  }

  .dropdown-list {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.dropdown-value-select {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;

  label {
    cursor: pointer;
    text-align: left;
  }
}

.dropdown-value-select input {
  z-index: 1;
  opacity: 0;
  position: absolute;
  height: 18px;
  width: 18px;
  left: 0;
  top: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #FF7222;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.dropdown-value-select:hover input ~ .checkmark {
  background-color: #FF763C26;
}

/* When the checkbox is checked, add a blue background */
.dropdown-value-select input:checked ~ .checkmark {
  background-color: #FF763C26;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dropdown-value-select input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.dropdown-value-select .checkmark:after {
  left: 6px;
  width: 4px;
  height: 13px;
  border: solid #FF7222;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}